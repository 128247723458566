import * as CONSTANTS from '../constants';

export function getUserRole(){
    // return 'SuperRole';
    const apiUrl = process.env.REACT_APP_DEV_ENV === "local" ? 'http://localhost:8080' : process.env.REACT_APP_API_URL;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + sessionStorage.getItem(CONSTANTS.SS_JWT_ACCESS_TOKEN));
    myHeaders.append('Content-Type', 'application/json');

    return fetch(apiUrl + `/audits/v1/getUserRole`, {
      method: "GET",
      headers: myHeaders,
      mode: 'cors',
      cache: 'default',
      credentials: 'include'
    });
}