import React, { useState, useEffect } from 'react';
import { getUserRole } from '../services/darsServices';
import Unauthorized from '../pages/Unauthorized';
import Papa from 'papaparse';
import * as CONSTANTS from '../constants';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const getCatalogYearBasedOnCurrentDate = () => {
  const date = new Date();
  if(date.getUTCMonth() >= 4 )
    return (date.getUTCFullYear() + '7')
  else
    return ((date.getUTCFullYear()-1) + '7')
}

function Home() {
  const [studentIdRadioButtonChecked, setStudentIdRadioButtonChecked] = useState(true);
  const [role, setRole] = useState(undefined);
  const [auditData, setAuditData] = useState([]);
  const [selectedUser, setSelectedUser] = useState('batchaudit');
  const [selectedServer, setSelectedServer] = useState('ASU_TA');
  const [alertData, setAlertData] = useState({});
  const [unmetAudit, setUnmetAudit] = useState(false);
  const [disableRun, setDisableRun] = useState("");

  const handleUnmetAuditCheckBox = (new_value) => {
    let new_audits = [...auditData];
    for (let i = 0; i < auditData.length; i++) {
      if(new_value){
        new_audits[i]['pntreq'] = 'N';
      }else{
        new_audits[i]['pntreq'] = ' ';
      }
    }
    setUnmetAudit(new_value)
    setAuditData(new_audits)
  }

  function handleFileUpload(event){
    const allowedExtensions = /(\.csv)$/i;
    const csvFile = event.target.files[0];
    const target = event.target;
    if (allowedExtensions.exec(csvFile.name)) {
      Papa.parse(csvFile, {complete: (results) => 
        {
          const audits = getValidateAuditData(results.data.filter(row => row.length > 0 ).filter(row => !(row.length === 1 && row[0]?.trim() === '')))
          if(audits.length){
            setAuditData(audits);
          }else {
            setAuditData([]);
            target.value = null;
          }
        }
      });
    }else {
      event.target.value = null;
      displayAlert("File type must be a .csv, please reupload and try again.", "alert-warning");
    }     
  }

  function handleFileUploadEvent(evt) { 
    setDisableRun("Loading File...");
    evt.persist();
    setTimeout(() => {
      try{
        handleFileUpload(evt);
      }
      catch(err){
        console.log(err);
      }
      finally {
        setDisableRun("");
      }
    }, 0);
  }

  function getValidateAuditData(parsedCsv){
    if(parsedCsv.length === 0){
      displayAlert("No Records found. Please check the file", "alert-warning");
      return [];
    }

    let audits = [];
    let message;
    let classList;
    let i;
    let new_format = false;
    if (studentIdRadioButtonChecked) {
      for (i = 0; i < parsedCsv.length; i++) {
        if (parsedCsv[i].length !== 5 && parsedCsv[i].length !== 1) {
          message = 'Invalid file format. The record at row ' + (i+1) + ' does not follow the format "emplid".';
          classList = "alert-warning";
          displayAlert(message, classList);
          return [];
        }
      }
      new_format = parsedCsv[0].length === 1;
    } else {
      for (i = 0; i < parsedCsv.length; i++) {
        if (parsedCsv[i].length !== 13 && parsedCsv[i].length !== 3 && parsedCsv[i].length !== 2) {
          message = 'Invalid file format. The record at row ' + (i+1) + ' does not follow the format "emplid,dprog,catyl".';
          classList = "alert-warning";
          displayAlert(message, classList);
          return [];
        }
      }
      new_format = parsedCsv[0].length === 3 || parsedCsv[0].length === 2;
    }
    const stuno_idx = new_format ? 0 : 4;
    const fdprog_idx = new_format ? 1 : 11;
    const fcatlyt_idx = new_format ? 2 : 12;
    for (i = 0; i < parsedCsv.length; i++) {
      audits[i] = {};
      audits[i]['stuno'] = parsedCsv[i][stuno_idx];
      audits[i]['comkey'] = parsedCsv[i][5] || 'ASU';
      audits[i]['evalsw'] = parsedCsv[i][6] || 'S';
      audits[i]['report'] = parsedCsv[i][7] || ' ';
      audits[i]['pntreq'] = parsedCsv[i][8] || ' ';
      if(unmetAudit){
        audits[i]['pntreq'] = 'N';
      }
      audits[i]['fdprog'] = parsedCsv[i][fdprog_idx] || ' ';
      while (audits[i]['fdprog'].length < 15) {
        audits[i]['fdprog'] = audits[i]['fdprog'] + " ";
      }
      audits[i]['fcatlyt'] = parsedCsv[i][fcatlyt_idx] || ' ';
      if(audits[i]['fcatlyt'] === ' ' && !studentIdRadioButtonChecked){
        audits[i]['fcatlyt'] = getCatalogYearBasedOnCurrentDate()
      }
      audits[i]['soprid'] = ' ';
      audits[i]['d_whatif'] = ' ';
      if (studentIdRadioButtonChecked) {
        audits[i]['user_seq_no'] = 1;
        audits[i]['f1or2'] = '';
        audits[i]['report'] = '';
        audits[i]['listall'] = '';
        audits[i]['test'] = '';
        audits[i]['sinstid'] = '';
        audits[i]['sinstcd'] = '';
        audits[i]['fdpmask'] = '';
        audits[i]['time_token'] = '';
        audits[i]['lasertext'] = '';
        audits[i]['format'] = '';
        audits[i]['parseflg'] = '';
        audits[i]['revart'] = '';
        audits[i]['binstid'] = '';
        audits[i]['binstcd'] = '';
        audits[i]['bytaken'] = '';
      } else {
        audits[i]['user_seq_no'] = 0;
        audits[i]['f1or2'] = ' ';
        audits[i]['report'] = ' ';
        audits[i]['listall'] = ' ';
        audits[i]['test'] = ' ';
        audits[i]['sinstid'] = ' ';
        audits[i]['sinstcd'] = ' ';
        audits[i]['fdpmask'] = ' ';
        audits[i]['time_token'] = ' ';
        audits[i]['lasertext'] = ' ';
        audits[i]['format'] = ' ';
        audits[i]['parseflg'] = ' ';
        audits[i]['revart'] = ' ';
        audits[i]['binstid'] = ' ';
        audits[i]['binstcd'] = ' ';
        audits[i]['bytaken'] = ' ';
      }
    }

    for (i = 0; i < audits.length; i++) {
      if (audits[i]['stuno'].length !== 10 || !isNumeric(audits[i]['stuno'])) {
        message = "Invalid format, emplid at row " + (i+1) + " must be numeric and 10 digits";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      } else if (audits[i]['comkey'] !== 'VER' && audits[i]['comkey'] !== 'ASU' && audits[i]['comkey'] !== 'REG') {
        message = "Comkey provided at row: " + (i+1) + " is invalid. Must be one of the following values: ASU, VER, or REG.";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      else if (audits[i]['evalsw'] !== 'A' && audits[i]['evalsw'] !== 'H' && audits[i]['evalsw'] !== 'E' && audits[i]['evalsw'] !== 'S' && audits[i]['evalsw'] !== 'D') {
        message = "Evalsw at row " + (i+1) + " is invalid, must be D, S, E, A, or H.";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      else if (audits[i]['report'] !== "" && audits[i]['report'] !== " " && audits[i]['report'] !== "" && audits[i]['report'] !== 'F' && audits[i]['report'] !== 'P' && audits[i]['report'] !== 'C' && audits[i]['report'] !== 'N') {
        message = "Report value at row " + (i+1) + " must be one of the following values: F, P, C, N, or null.";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      else if (audits[i]['pntreq'] !== " " && audits[i]['pntreq'] !== "" && audits[i]['pntreq'] !== "N" && audits[i]['pntreq'] !== "Y") {
        message = "Prntreq value at row " + (i+1) + " is invalid. Accepted values are: N, Y, or null.";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      else if (audits[i]['listall'] !== " " && audits[i]['listall'] !== "" && audits[i]['listall'] !== "L" && audits[i]['listall'] !== "M" && audits[i]['listall'] !== "L" && audits[i]['listall'] !== "P" && audits[i]['listall'] !== "H" && audits[i]['listall'] !== "V") {
        message = "Listall value at row " + (i+1) + " is invalid. Accepted values are: L, M, S, P, H, V, or null.";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      else if (audits[i]['fdprog'].length !== 15) {
        message = "Fdprog length at row " + (i+1) + " is " + audits[i]['fdprog'].length + ". Length must be 15. ";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      else if (audits[i]['soprid'] !== "*" && audits[i]['soprid'] !== " ") {
        message = "Invalid soprid for row " + (i+1) + ". Accepted values: * or blank";
        classList = "alert-warning";
        displayAlert(message, classList);
        return [];
      }
      // else if (audits[i]['whatif'] !== "" && audits[i]['whatif'] !== "A" && audits[i]['whatif'] !== "P" && audits[i]['whatif'] !== "T") {
      //     message = "Invalid whatif column entry at row " +i+ ". Accepted values: A, P, T, or null";
      //     classList = "alert-warning";
      //     displayAlert(message, classList);
      //     return;
      //   }
      //parse this variable to validate fields
      else if (audits[i]['fcatlyt'] !== " ") {
        if (audits[i]['fcatlyt'].length !== 5 || !isNumeric(audits[i]['fcatlyt'])) {
          message = "Invalid format, fcatlyt at row " + (i+1) + " must be numeric and 5 digits";
          classList = "alert-warning";
          displayAlert(message, classList);
          return [];
        }
        if (audits[i]['fcatlyt'].charAt(4) !== '7' && audits[i]['fcatlyt'].charAt(4) !== '4' && audits[i]['fcatlyt'].charAt(4) !== '1') {
          message = "Term year at row " + (i+1) + " must be fall, summer or spring represented with the value 7, 4 or 1";
          classList = "alert-warning";
          displayAlert(message, classList);
          return [];
        }
      }
    }
    return audits;
  }

  function displayAlert(message, classList){
    setAlertData({ message: message, classList: classList, showAlert: true});
    setTimeout(() => {
      setAlertData({ message: message, classList: classList, showAlert: false});
    }, 6000);
  }

  async function createAudits() {
    if (auditData.length) {
      let request = {
        fileType: studentIdRadioButtonChecked ? 'degreeProg': 'whatIf',
        serverName: selectedServer,
        userId: selectedUser,
        audits: auditData
      };
      const apiUrl = process.env.REACT_APP_DEV_ENV === "local" ? "http://localhost:8080" : process.env.REACT_APP_API_URL;
      const myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.getItem(CONSTANTS.SS_JWT_ACCESS_TOKEN));
      myHeaders.append('Content-Type', 'application/json');
      const response = await fetch(apiUrl + `/audits/v1/create`, {
        method: "POST",
        mode: 'cors',
        cache: 'default',
        credentials: 'include',
        headers: myHeaders,
        body: JSON.stringify({ request }),
      });
      if (response.ok) {
        displayAlert("Successfully submitted audit batch.", "alert-success");
        document.getElementById('file').value = null;
        setAuditData([]);
      }
      else {
        displayAlert((await response.json()).error, "alert-warning");
      }
    }
    else {
      displayAlert("Please upload a .csv file to submit an audit batch.", "alert-warning");
    }
  }

  async function handleRunClick() {
    setDisableRun("Processing...");
    try{
      await createAudits();
    }
    catch(err){
      console.log(err);
    }
    finally{
      setDisableRun("");
    }
  }

  useEffect(() => {
    function handleUserRole(){
      getUserRole()
      .then((response) => 
      {
        if(response.status === 401){
          setTimeout(handleUserRole, 100);
        }else if(response.status !== 200){
          throw new Error("Unable to fetch Role")
        }else{
          return response.text();
        }
      })
      .then((result) => setRole(result))
      .catch((error) => {
        displayAlert(error.message, "alert-warning");
        setRole('');
      });
    }
    handleUserRole();    
  }, [role]);

  let maincontent;
  let userOptions = [
      { id: 'batchaudit', value: 'Degree Audit'},
      { id: 'dwbatch', value: 'DARS to Data Warehouse'}
  ];
  let serverOptions = [{ id: 'ASU_TA', value: 'ASU_TA'}];

  if(role === undefined){
    maincontent = <></>;
  }else if(role !== 'SuperRole' && role !== 'SuperUser' && role !== 'Encoders'){
  maincontent = <Unauthorized />;
  }else{
    if((role === 'SuperRole' || role === 'SuperUser')){
       userOptions = [
        { id: 'batchaudit', value: 'Degree Audit'},
        { id: 'gradbatch', value: 'Graduation Audit'},
        { id: 'dwbatch', value: 'DARS to Data Warehouse'},
        { id: 'prgmatcher', value: 'Program Matcher'},
      ];
      serverOptions = [
        { id: 'ASU_TA', value: 'ASU_TA'},
        { id: 'ASU', value: 'ASU'},
        { id: 'ASU_BATCH', value: 'ASU_BATCH'},
        { id: 'ASU_BATCH2', value: 'ASU_BATCH2'}
      ];
    }
    maincontent = 
    <>
    <div style={{width: '60%', margin: 'auto'}}>     
          <h3>Run Batch Degree Audit</h3>
                <label>File Type</label><br/>
                <div>
                  <input type="radio" checked={studentIdRadioButtonChecked} onChange={() => setStudentIdRadioButtonChecked(true)}/>
                  &nbsp;
                  <span style={{fontWeight: 'bold'}}>Student IDs only</span><br/>
                  <div style={{marginLeft: '16px'}}>
                    <span>A comma separated list (file type .csv) of Student IDs</span> <br/>
                    <span> <span style={{fontWeight: 'bold'}}>Example : </span> emplid </span>
                  </div>
                  <br />
                  <input type="radio" checked={!studentIdRadioButtonChecked} onChange={() => setStudentIdRadioButtonChecked(false)}/>
                  &nbsp;
                  <span style={{fontWeight: 'bold'}}>Student ID, Degree Program, Catalog Year/Term</span><br/>
                  <div style={{marginLeft: '16px'}}>
                    <span>A comma separated list (file type .csv) of Student IDs, Degree Program, Catalog Year/Term</span> <br/>
                    <span> <span style={{fontWeight: 'bold'}}>Example : </span> emplid,dprog,catyl </span>
                  </div>
                  <br />
                  <input type="checkbox" id="unmet" name="unmet" checked={unmetAudit} onChange={() => handleUnmetAuditCheckBox(!unmetAudit)}/>
                    &nbsp;
                    <label htmlFor="unmet">Unmet Audit</label>
                  <br />
                  <label>File Name</label>
                  &nbsp;
                  <input
                    id="file"
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileUploadEvent}
                  />
                  <br />
                  <br />
                  <label>User ID</label>
                  {/* <UserIdDropdownMenu role={sessionStorage.getItem('SS_ROLE')} /> */}
                  <div onChange={(e)=>setSelectedUser(e.target.value)}>
                  <select>
                    {userOptions.map((option)=> 
                      <option key={option.id} value={option.id}>{option.value}</option>
                    )}
                  </select>
                  <br></br>
                  <br></br>
                  </div>
                  <label>Server Name</label>
                  <div onChange={(e)=>setSelectedServer(e.target.value)}>
                  <select>
                  {serverOptions.map((option)=> 
                  <option key={option.id} value={option.id}>{option.value}</option>)}
                  </select>
                  <br></br>
                  <br></br>
                  </div>
                  {/* <MaxRecord role={sessionStorage.getItem('SS_ROLE')} /> */}
                  <button style={{backgroundColor: '#FFC72C'}} disabled={disableRun !== ""} className='btn btn-md' onClick={handleRunClick}>
                    { disableRun ? disableRun : "Run" }
                  </button>
              </div>
        </div>
    </>;
  }
  return (
  <>
  {alertData.showAlert && (
    <div className={`alert alert-position ${alertData.classList}`} role="alert" style={{ cursor: 'pointer', justifyContent: 'center', display: 'flex' }}>
      {alertData.message}
      <button type="button" className="close" aria-label="Close" onClick={() => setAlertData({ showAlert: false})}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )}
  {maincontent}
  </>);
}

export default Home;
