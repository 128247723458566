export const SERVICEAUTH_REDIRECT_URI = ( process.env.REACT_APP_DEV_ENV === "local" ? 'https://localhost.asu.edu:3000' : process.env.REACT_APP_CLIENT_URL ) + "/"
export const serviceauthOauthUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/native/allow';
export const serviceauthRefreshUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/token';
export const serviceauthId = process.env.REACT_APP_HOST_ENV === "prod" ? "batchaudits-app" : "batchaudits-app-nonprod";
export const scopes = [
    'https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self',
    'https://api.myasuplat-dpl.asu.edu/scopes/person/read:self',
    ( process.env.REACT_APP_DEV_ENV === "local" ? 'https://localhost.asu.edu:3000' : process.env.REACT_APP_CLIENT_URL ) + '/authorize:self'
];
export const serviceauthSecret = 'serviceauth-public-agent';
export const serviceauthTokenUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/native/token';
export const SS_CODE_VERIFIER = 'code_verifier';
export const SS_JWT_ACCESS_TOKEN = 'batchaudits.serviceauth.access_token';
export const SS_JWT_REFRESH_TOKEN = 'batchaudits.serviceauth.refresh_token';
export const DPL_BASE_URL = 'https://api.myasuplat-dpl.asu.edu/api';
export const LOGOUT_URL = "https://weblogin.asu.edu/cas/logout";
