function Unauthorized() {
    return (
        <div style={{textAlign: 'center', marginTop: '10%'}}>
            <h2 style={{textAlign: 'center'}}>Oops!</h2>
            <p>Looks like you do not have access to this page</p>
        </div>
    );
  }
  
  export default Unauthorized;
  