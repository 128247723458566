import sjcl from 'sjcl';
import * as CONSTANTS from '../constants';
import $ from "jquery";

function generateRandomString(alphabet, length) {
    const randArr = new Uint8Array(length);
    let ret = "";
    window.crypto.getRandomValues(randArr);
    for (let i = 0; i < randArr.length; i++) {
        const j = randArr[i] % 64;
        ret += alphabet.substring(j, j + 1);
    }
    return ret;
} 

function generateCodeVerifier() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";
    const length = 128;
    return generateRandomString(alphabet, length);
}
    
function sha256base64url(message) {
    return sjcl.codec.base64url.fromBits(sjcl.hash.sha256.hash(message));
}
  
function generateRandomServiceauthState() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";
    const length = 64;
    return generateRandomString(alphabet, length)
}
 
export function redirectToServiceauth() {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = sha256base64url(codeVerifier);
    const state = generateRandomServiceauthState();
    let scopeParam = '';
    for (var i = 0; i < CONSTANTS.scopes.length; i++) {
        if (scopeParam.length > 0) {
        scopeParam += ' ';
        }
        scopeParam += CONSTANTS.scopes[i];
    }
    sessionStorage.setItem(CONSTANTS.SS_CODE_VERIFIER, codeVerifier);
    let url = CONSTANTS.serviceauthOauthUrl;
    url += '?response_type=code';
    url += '&client_id=' + encodeURIComponent(CONSTANTS.serviceauthId);
    url += '&redirect_uri=' + encodeURIComponent(CONSTANTS.SERVICEAUTH_REDIRECT_URI);
    url += '&state=' + encodeURIComponent(state);
    url += '&code_challenge_method=S256';
    url += '&code_challenge=' + codeChallenge;
    url += '&scope=' + encodeURIComponent(scopeParam);
    console.log('redirecting to oauth server at url=[' + url + ']');
    window.location.replace(url);
}

export function verifyServiceAuthCode(serviceAuthCode, callDpl){

    const payload = {
        grant_type: "authorization_code",
        code: serviceAuthCode,
        redirect_uri: CONSTANTS.SERVICEAUTH_REDIRECT_URI,
        client_id: CONSTANTS.serviceauthId,
        client_secret: CONSTANTS.serviceauthSecret,
        code_verifier: sessionStorage.getItem(CONSTANTS.SS_CODE_VERIFIER),
    }

    $.ajax({
        type: 'POST',
        url: CONSTANTS.serviceauthTokenUrl,
        mode: 'cors',
        cache: false,
        data: payload,
        success: (data) => {
            sessionStorage.removeItem(CONSTANTS.SS_CODE_VERIFIER);
            sessionStorage.setItem(CONSTANTS.SS_JWT_ACCESS_TOKEN, data.access_token);
            sessionStorage.setItem(CONSTANTS.SS_JWT_REFRESH_TOKEN, data.refresh_token);
            callDpl((data.expires_in -10)* 1000);
        },
        error: (xhr, status, error) => {
            console.log(error);
            console.log(status);
            console.log(JSON.stringify(xhr));
        }     
      });
}

export function refreshServiceAuthToken(){
    let scopeParam = '';
    for (var i = 0; i < CONSTANTS.scopes.length; i++) {
      if (scopeParam.length > 0) {
        scopeParam += ' ';
      }
      scopeParam += CONSTANTS.scopes[i];
    };

    let requestData = {
      grant_type: 'refresh_token',
      refresh_token: sessionStorage.getItem(CONSTANTS.SS_JWT_REFRESH_TOKEN),
      client_id: CONSTANTS.serviceauthId,
      client_secret: CONSTANTS.serviceauthSecret,
      scope: scopeParam
    };

    $.ajax({
      type: "POST",
      url: CONSTANTS.serviceauthRefreshUrl,
      cache: false,
      data: requestData,
      success: function (data) {
        console.log('token refresh success! data=[' + JSON.stringify(data));
        sessionStorage.setItem(CONSTANTS.SS_JWT_ACCESS_TOKEN, data.access_token);
      },
      error: function (xhr, status, error) {
        console.log("error: xhr: [" + JSON.stringify(xhr) + "] status=[" + status + "], error=[" + error + "]");
      }
    });
}