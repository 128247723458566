import React from "react";
import { render } from "react-dom";
import "./index.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Unauthorized from "./pages/Unauthorized";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@asu/unity-bootstrap-theme/dist/css/unity-bootstrap-theme.bundle.css";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Unauthorized />,
  },
]);

render(
  <React.StrictMode>
    <div id="header">
      <Header />
    </div>
    <div id="app">
      <RouterProvider router={router} />
    </div>
    <div id="footer">
      <Footer />
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
