// @ts-check
import styled from "styled-components";
import { ASUHeader } from "@asu/component-header/dist/asuHeader.es";
// @ts-ignore
import desktopLogo from "../images/arizona-state-university-logo-vertical.png";
// @ts-ignore
import mobileLogo from "../images/arizona-state-university-logo.png";
import React, { useState, useEffect } from "react";
import {
  redirectToServiceauth,
  verifyServiceAuthCode,
  refreshServiceAuthToken,
} from "../services/serviceAuth";
import * as CONSTANTS from "../constants";
import $ from "jquery";

const HeaderSection = styled.div`
  header {
    position: sticky;
    a {
      cursor: pointer;
    }
  }
`;

let accessTokenRefreshInterval;

function Header() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [code, setCode] = useState(null);

  function callDpl(intervalMs) {
    accessTokenRefreshInterval = window.setInterval(function () {
      refreshServiceAuthToken();
    }, intervalMs);
    $.ajax({
      type: `GET`,
      cache: false,
      dataType: `json`,
      url: `${CONSTANTS.DPL_BASE_URL}/principal/me`,
      beforeSend: (xhr) =>
        xhr.setRequestHeader(
          `Authorization`,
          `Bearer ${sessionStorage.getItem(CONSTANTS.SS_JWT_ACCESS_TOKEN)}`
        ),
      success: (data) => {
        $.ajax({
          type: `GET`,
          cache: false,
          dataType: `json`,
          url: CONSTANTS.DPL_BASE_URL + "/person/" + data.owner.emplid,
          beforeSend: (xhr) =>
            xhr.setRequestHeader(
              `Authorization`,
              `Bearer ${sessionStorage.getItem(CONSTANTS.SS_JWT_ACCESS_TOKEN)}`
            ),
          success: (dpl_data) => {
            setUserName(dpl_data.names.PRI.givenName);
            setLoggedIn(true);
          },
          error: (xhr, status, error) => {
            console.log(
              `principal error: xhr: [${JSON.stringify(
                xhr
              )}] status=[${status}], error=[${error}]`
            );
          },
        });
      },
      error: (xhr, status, error) => {
        console.log(
          `principal error: xhr: [${JSON.stringify(
            xhr
          )}] status=[${status}], error=[${error}]`
        );
      },
    });
  }

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop.toString()),
    });
    if (params["code"]) {
      const serviceAuthCode = params["code"];
      window.history.replaceState(
        {},
        document.title,
        CONSTANTS.SERVICEAUTH_REDIRECT_URI
      );
      verifyServiceAuthCode(serviceAuthCode, callDpl);
      setCode(params["code"]);
      return;
    }
    if (!code) {
      redirectToServiceauth();
    }
  }, [code]);

  return (
    <HeaderSection>
      <ASUHeader
        logo={{
          alt: "logo",
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={loggedIn}
        userName={userName}
        title={"Batch Degree Audit"}
        onLogoutClick={() => {
          sessionStorage.removeItem(CONSTANTS.SS_JWT_ACCESS_TOKEN);
          sessionStorage.removeItem(CONSTANTS.SS_JWT_REFRESH_TOKEN);
          clearInterval(accessTokenRefreshInterval);
          window.location.href = CONSTANTS.LOGOUT_URL;
        }}
      />
    </HeaderSection>
  );
}

export default Header;
